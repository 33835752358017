:root {
    --cisco-blue: #049fd9;
    --cisco-blue-burned: #2b5592;
    --dark-grey: #39393b;
    --light-grey: #9e9ea2;
    --st-green: #6cc04a;
    --st-red: #ab0033;
    --st-yellow: #fc0;
}

body {
    background: white;
    color: var(--dark-grey);
}

.container {
    display: -ms-grid;
    display: grid;
    width: 960px;
    margin: auto;
    -ms-grid-columns: 46% 46%;
    grid-template-columns: repeat(2, 46%);
    grid-gap: 0 8%;
}

.container h1 {
    margin: 0em 0 0.75em;
    -ms-grid-column-span: 2;
    grid-column-start: span 2;
    font-size: 50px;
    letter-spacing: -0.05em;
    font-weight: 100;
}

.sidebar {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
}

.main {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
}

.header-logo {
    height: 25px;
}

footer {
    padding-top: 80px;
    font-size: 16px;
}

.errorMsg {
    margin-top: 15px;
    font-size: 13px;
    color: var(--st-red);
}

h2 {
    margin: 0;
    font-size: 26px;
    letter-spacing: -0.025em;
    font-weight: 200;
}

h3 {
    margin: 0 0 1em 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08em;
}

.whats-included,
.next-steps {
    padding: 0;
    list-style: none;
}

.whats-included p,
.next-steps p {
    padding: 0 0 0 2em;
    margin-bottom: 25px;
    color: #444456;
    font-size: 16px;
    letter-spacing: 0;
}

.whats-included .i-allowed {
    float: left;
    line-height: 1;
}

.personal p {
    margin: 0;
    padding: 10px 0 30px 100px;
    font-size: 16px;
    letter-spacing: -0.025em;
}

.personal__icon {
    position: relative;
    top: 5px;
    float: left;
    width: 80px;
}

.terms {
    display: flex;
    list-style: none;
    justify-content: flex-end;
}

.terms li {
    margin-left: 2em;
}

.terms a,
.terms a:hover {
    color: #333;
}

.fine-print {
    font-size: 12px;
}

.message-area {
    display: flex;
    flex-wrap: nowrap;

    & .message-icon {
        flex: 0 0 auto;
    }

    & .message-text {
        flex: 1 1 auto;
    }
}

.icon {
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.icon--small {
    width: 13px;
}

.icon--cancel-inverted {
    background-image: url('/img/cancel-inverted.svg');
}

.button-container button {
    position: relative;
}
