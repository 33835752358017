@media (max-width: 960px) {

    .container {
        display: block;
        box-sizing: border-box;
        max-width: 400px;
        padding-left: 10px;
        padding-right: 10px;
        margin: auto;
    }
}